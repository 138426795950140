import Roles from "src/config/Roles";
import { isDefined, isDefinedAndNotVoid } from "src/helpers/utils";

export const update = (data, tourings, setTourings) => {
    const newTourings = tourings.map(touring => touring.id === data.id ? data : touring);
    setTourings(newTourings);
}

export const updateTourings = (data, setData, tourings, setTourings, currentUser, dates) => {
    const { start, end } = formatUTC(dates);
    const newData = data.map(touring => {
        const deliveryDate = new Date(touring.start);
        if (deliveryDate >= start && deliveryDate <= end && isUserAllowed(touring.deliverer, currentUser)) {
            if (!isDefined(touring.id) || !isDefined(touring.isOpen) || !touring.isOpen) {
                const filteredTourings = tourings.filter(t => t['@id'] !== touring['@id'])
                setTourings(filteredTourings);
            } else {
                const existingTouring = tourings.map(t => t.id).includes(touring.id);
                if (existingTouring) {
                    const updatedTourings = tourings.map(t => t.id === touring.id ? touring : t);
                    setTourings(updatedTourings);
                } else {
                    setTourings([...tourings, touring]);
                }
            }
        }
        return {...touring, treated: true};
    });
    setData(newData.filter(d => !isDefined(d.treated) || !d.treated));
    return new Promise((resolve, reject) => resolve(false));
}

const formatUTC = dates => ({ start: new Date(dates.start.toUTCString()), end: new Date(dates.end.toUTCString()) });

const isUserAllowed = (deliverer, currentUser) => {

    if (Roles.isPicker(currentUser) || Roles.hasAdminPrivileges(currentUser))
        return true;
    else if (isDefined(deliverer) && isDefinedAndNotVoid(deliverer.users))
        return deliverer.users.map(u => u.id).includes(currentUser.id);

    return false
}