import axios from 'axios';
import api from 'src/config/api';
import { getInt, isDefined } from 'src/helpers/utils';

function find() {
    return api
        .get('/platforms')
        .then(response => response.data['hydra:member'][0]);
}

function deletePlatform(id) {
    return api.delete('/platforms/' + id);
}

function update(id, platform) {
    return api.put('/platforms/' + id, {...platform});
}

function create(platform) {
    return api.post('/platforms', {...platform});
}

function createImage(image) {
    let formData = new FormData();
    formData.append('file', image);
    formData.append('instance', 'Platform');
    return api.post('/pictures', formData, {headers: {'Content-type': 'multipart/form-data'}})
              .then(response => response.data);
}

function createLogo(image, type, owner = null) {
    let formData = new FormData();
    formData.append('file', image);
    formData.append('instance', type);
    const rootRequest = isDefined(owner) ? `/pictures?owner=${ owner.id }` : `/pictures`;
    return api.post(rootRequest, formData, {headers: {'Content-type': 'multipart/form-data'}})
              .then(response => response.data['@id']);
}

function getSMSBalance() {
    return api.get('/sms/balance')
              .then(response => isDefined(response) && isDefined(response.data) ? getInt(response.data) : null);
}

export default {
    find, 
    delete: deletePlatform,
    update,
    create,
    createImage,
    createLogo,
    getSMSBalance
}