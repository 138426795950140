import api from 'src/config/api';

function findAll() {
    return api
        .get('/sellers?order[name]=asc')
        .then(response => response.data['hydra:member']);
}

function findSellerMakers() {
    return api
        .get('/sellers?hasPhysicSales=true&order[name]=asc')
        .then(response => response.data['hydra:member']);
}

function findSellersNeedingRecovery() {
    return api
        .get('/sellers?needsRecovery=true&order[name]=asc')
        .then(response => response.data['hydra:member']);
}

function findActiveSellers() {
    return api
        .get('/sellers?isActive=true&order[name]=asc')
        .then(response => response.data['hydra:member']);
}

function findAllPaginated(page = 1, items = 30) {
    return api
        .get(`/sellers?order[name]=asc&pagination=true&itemsPerPage=${ items }&page=${ page }`)
        .then(response => response.data)
        .catch(error => []);
}

function findWord(word, page = 1, items = 30) {
    return api
        .get(`/sellers?name=${ word }&order[name]=asc&pagination=true&page=${ page }&itemsPerPage=${ items }`)
        .then(response => response.data)
        .catch(error => []);
}

function deleteSeller(id) {
    return api.delete('/sellers/' + id);
}

function find(id) {
    return api
        .get('/sellers/' + id)
        .then(response => response.data);
}

function update(id, seller) {
    return api.put('/sellers/' + id, {...seller});
}

function create(seller) {
    return api.post('/sellers', {...seller});
}

function createImage(image) {
    let formData = new FormData();
    formData.append('file', image);
    formData.append('instance', "SELLER-LOGO");
    return api.post('/pictures', formData, {headers: {'Content-type': 'multipart/form-data'}})
              .then(response => response.data);
}

export default {
    findAll,
    findAllPaginated,
    findActiveSellers,
    findSellerMakers,
    findSellersNeedingRecovery,
    findWord,
    delete: deleteSeller,
    find,
    update,
    create,
    createImage
}